<template>
  <div class="bottom-nav" v-if="$route.meta.showBottomNav">
    <ul class="dfr-Y-center">
      <li v-for="(item, i) in catalogue" :key="'catalogue'-item.id" @click="goTagPage(item.path)" class="dfr-XY-center"
          :class="{active: $route.path === item.path}">
        <div class="dfr-XY-center">
          <span class="label label-first text-center">{{ item.label.slice(0,1) }}</span>
          <span class="label label-other">{{ item.label.slice(1, 20) }}</span>
          <span class="bottom-nav-bar-line"></span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import catalogue from "@/data/catalogue";

export default {
  name: 'BottomNav',
  data() {
    return {
      catalogue
    }
  },

  components: {},
  created() {
  },
  methods: {
    goTagPage(path) {
      if (path === this.$route.path) {
        return
      }
      this.$router.replace(path)
    }
  }
}
</script>

<style scoped lang="less">
.bottom-nav {
  height: @bottom-nav-height;
  width: 100%;

  ul {
    justify-content: space-between;
    height: 100%;

    li {
      flex: 1;

      >div{
        position: relative;
        font-family: mine-font-En2;
        font-size: 2rem;
        .bottom-nav-bar-line{
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 1px;
          background-color: @black;
          transition: all .7s;
        }
      }

      .label {
        transition: all .5s;
        &.label-other{
          width: 0;
          overflow: hidden;
        }
      }


      &.active {
        .label-other{
          width: 3rem;
        }
        .bottom-nav-bar-line{
          width: 2rem;
        }
      }
    }
  }
}
</style>
