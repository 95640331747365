<template>
  <div class="top-bar" :class="[inApp]" v-if="$route.meta.showTopBar">
    <ul class="dfr-Y-center">
      <li>
        <i class="iconfont icon-direction-left" v-if="$route.meta.showBack" @click="goBack"></i>
        <i class="iconfont icon-caidan1" v-else @click="controlPanelShowFlag = true"></i>
      </li>
      <li class="title">
        <span>{{ topTitle }}</span>
      </li>
      <li class="other"></li>
    </ul>
    <van-popup class="controlPanel"
               v-model="controlPanelShowFlag"
               position="left"
               get-container="#app"
               :style="{ width: '70%', height: '100%' }">
      <div class="mine-control-panel-content dfc" @click.stop="" v-if="userInfo">
        <div class="userInfo dfr-XY-center p-1">
          <div class="avatarImg mr-1" @click="goTagPage('/user-info')"
               :style="`background-image:url(${userInfo.avatarImg || defaultAvatarImg});`">
          </div>
          <div class="otherInfo dfc">
            <p class="userName dfr">
              <!--            <nameplate :id="1"></nameplate>-->
              <span>{{ userInfo.userName }}</span>
            </p>
            <p class="registerDate">注册于{{ userInfo.registerDate | dateFormat('YYYY-MM-DD') }}</p>
          </div>
        </div>
        <ul class="controlBox">
          <li v-for="(item, i) in controlList" :key="item.id" class="wi-card dfr-Y-center" @click="goTagPage(item.path)">
            <p class="dfr-Y-center"><i class="iconfont" :class="item.icon"></i>{{ item.label }}</p>
            <i class="iconfont icon-arrow-right"></i>
          </li>
        </ul>
        <div class="logout p-1 dfr-XY-center">
          <i class="iconfont icon-zhuxiao" @click="logout"></i>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: 'TopBar',
  props: {
    showBack: {
      type: Boolean,
      default: false
    },
    title: String,
    showAddBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      controlPanelShowFlag: false,
      defaultAvatarImg: require('../../assets/images/defaultAvatar.png'),
      controlList: [
        {
          path: '/user-info',
          label: '个人信息',
          icon: 'icon-RectangleCopy'
        },
        // {
        //   path: '',
        //   label: '历史公告',
        //   icon: 'icongonggao'
        // }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
    inApp() {
      let UA = navigator.userAgent
      return UA === 'mineAPP' ? 'inApp' : ''
    },
    topTitle() {
      let str = this.title || this.$route.meta.title || this.$route.query.title || ''
      return str
    }
  },
  components: {},
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    logout() {
      this.$cookies.remove('TOKEN')
      this.$router.push('/login')
      this.controlPanelShowFlag = false
    },
    goTagPage(path) {
      if (path === this.$route.path) {
        return
      }
      this.$router.push(path)
      this.controlPanelShowFlag = false
    }
  }
}
</script>

<style scoped lang="less">
.top-bar {
  height: @top-bar-height;
  font-size: @top-bar-font-size;

  &.inApp {
    height: @top-bar-height + @in-app-padding-top;
    padding-top: @in-app-padding-top;
  }

  ul {
    width: 100%;
    height: @top-bar-height;

    li {
      position: relative;
      width: 0;
      flex: 1;

      i {
        font-size: 2rem;
        padding: 0 .5rem;
        line-height: @top-bar-height;

        &.icongfcaidan,
        &.icon-add {
          font-size: 2.6rem;
        }
      }

      &:nth-of-type(1) {
        text-align: left;
      }

      &:nth-of-type(3) {
        text-align: right;
      }

      &.title {
        text-align: center;
      }
    }
  }
}

.controlPanel {
  max-width: 400px;
  background-color: @background-color;
  padding: 1rem;

  .mine-control-panel-content {
    height: 100%;

    .userInfo {
      position: relative;
      font-size: 1.4rem;
      height: 10rem;

      .avatarImg {
        padding: 2px;
        border-radius: 50%;
        width: 5rem;
        height: 5rem;
        background-size: cover;
        overflow: hidden;
      }

      .otherInfo {
        width: 0;
        flex: 1;
        line-height: 2rem;

        .userName {

        }

        .registerDate {
          font-size: 1rem;
          color: @gray-7;
        }
      }
    }

    .controlBox {
      height: 0;
      flex: 1;
      font-size: 1.4rem;

      li {
        padding: 1rem;
        margin-bottom: 1rem;

        p {
          padding: .5rem 0;
          width: 0;
          flex: 1;
          i{
            font-size: 2rem;
            margin-right: .5rem;
          }
        }
        > i {
          font-size: 1.6rem;
          color: @gray-6;
        }
      }
    }

    .logout {
      height: 10rem;

      .icon-zhuxiao {
        font-size: 2rem;
        font-weight: bold;
      }
    }

  }
}
</style>
