import {fetchGet, fetchPost} from '@/util/request'

// 登录
export function login(data) {
  return fetchPost('/login', data)
}

// 注册
export function register(data){
  return fetchPost('/register', data)
}

// 获取用户信息
export function getUserInfo(){
  return fetchGet('/user/getUserInfo')
}

// 修改用户信息
export function updateUserInfo(data){
  return fetchPost('/user/updateUserInfo', data)
}

// 修改密码
export function updatePassword(data){
  return fetchPost('/user/updatePassword', data)
}
