<template>
  <div class="wi-input dfr-Y-center" :class="{disabled}">
    <i class="iconfont" :class="labelIcon" v-show="labelIcon"></i>
    <input :type="type" :value="inputValue" :placeholder="placeholder" @keyup="keyup" :maxlength="maxLength"
           @input="keyup" :disabled="disabled">
    <i v-if="!disabled" class="iconfont icon31guanbi input-icon-clean" v-show="inputValue" @click="clear"></i>
    <i v-if="!disabled" class="iconfont icontixing input-icon-error" v-show="showErrIcon"></i>
  </div>
</template>

<script>
export default {
  name: 'WiInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
      require: true,
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: String,
    showErrIcon: {
      type: Boolean,
      default: false
    },
    labelIcon: String,
    maxLength: Number,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal
    }
  },
  components: {},
  created() {
  },
  methods: {
    keyup(e) {
      this.$emit('input', e.target.value)
    },
    clear() {
      this.$emit('input', '')
    }
  }
}
</script>

<style scoped lang="less">
.wi-input {
  position: relative;
  height: @wi-input-height;
  padding: 0 .5rem;
  background-color: @gray-3;
  border-radius: @wi-input-height / 2;

  i {
    margin-left: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }

  .input-icon-clean,
  .input-icon-error {
    margin: 0 !important;
  }

  .input-icon-clean {
    font-size: 1.4rem;
    padding: 0 .5rem;
  }

  .input-icon-error {
    color: @color-error !important;
    font-weight: normal;
  }

  input {
    flex: 1;
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    line-height: @wi-input-height;
    margin: 0 .5rem 0 1rem;
  }

  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: @gray-6;
  }

  input:-moz-placeholder, textarea:-moz-placeholder {
    color: @gray-6;
  }

  input::-moz-placeholder, textarea::-moz-placeholder {
    color: @gray-6;
  }

  input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: @gray-6;
  }
}
</style>
