// 数组乱序
if (!Array.prototype.shuffle) {
  Array.prototype.shuffle = function () {
    for (let i = this.length - 1; i >= 0; i--) {
      let rIndex = Math.floor(Math.random() * (i + 1));
      let temp = this[rIndex];
      this[rIndex] = this[i];
      this[i] = temp;
    }
    return this
  }
}
