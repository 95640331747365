import {dateFormat} from '@/util/common'
import Vue from 'vue'
const filters = {
  dateFormat,
  computedDays(date) {
    return parseInt((parseInt(new Date().getTime() / 1000) - new Date(date).getTime() / 1000) / 60 / 60 / 24)
  }
}

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
