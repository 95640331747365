import Vue from 'vue'
import VueRouter from 'vue-router'
import {getToken} from '@/util/token'
import {openPageCache} from '@/config'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/home'),
    meta: {
      keepAlive: openPageCache,
      showBottomNav: true,
      showTopBar: true,
      showBack: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/login'),
    meta: {
      showBottomNav: false,
      showTopBar: false
    }
  },
  {
    path: '/days',
    name: 'Days',
    component: () => import(/* webpackChunkName: "about" */ '@/views/days'),
    meta: {
      keepAlive: openPageCache,
      title: '纪念日',
      showBottomNav: true,
      showTopBar: true,
      showBack: false
    }
  },
  {
    path: '/excerpt-group',
    name: 'ExcerptGroup',
    component: () => import(/* webpackChunkName: "about" */ '@/views/excerpt-group'),
    meta: {
      keepAlive: openPageCache,
      title: '摘抄分组',
      showBottomNav: true,
      showTopBar: true,
      showBack: false
    }
  },
  {
    path: '/excerpt-list',
    name: 'ExcerptList',
    component: () => import(/* webpackChunkName: "about" */ '@/views/except-list'),
    meta: {
      keepAlive: openPageCache,
      showBottomNav: false,
      showTopBar: true,
      showBack: true
    }
  },
  {
    path: '/excerpt-detail',
    name: 'ExcerptDetailPage', // 与ExcerptDetail区分
    component: () => import(/* webpackChunkName: "about" */ '@/views/excerpt-detail'),
    meta: {
      title: '摘抄详情',
      keepAlive: openPageCache,
      showBottomNav: false,
      showTopBar: true,
      showBack: true
    }
  },
  {
    path: '/user-info',
    name: 'UserInfo',
    component: () => import(/* webpackChunkName: "about" */ '@/views/user-info'),
    meta: {
      title: '个人信息',
      showBottomNav: false,
      showTopBar: true,
      showBack: true
    }
  },
]


const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  // 登录拦截
  if (!getToken() && to.name !== 'Login') { // 没有Token且去往页面不为Login时跳转至login页面
    next({
      path: 'login'
    })
    return
  }
  next()

})
export default router
