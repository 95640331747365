import Vue from 'vue'
const importFn = require.context('./', false, /\.vue$/)

importFn.keys().forEach(filepath => {
  const component = importFn(filepath).default
  const componentName = component.name
  Vue.component(
    componentName,
    component
  )
})
