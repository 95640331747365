<template>
  <div class="sideToolButton dfc-XY-center" :style="`bottom:${bottom};`" @click.stop="clickBtn">
    <i class="iconfont" :class="icon" v-if="icon"></i>
    <span v-if="text">{{text}}</span>
  </div>
</template>

<script>
export default {
  name: 'SideToolButton',
  props: {
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    bottom: {
      type: String,
      default: '5rem'
    }
  },
  data() {
    return {}
  },
  components: {},
  created() {
  },
  methods: {
    clickBtn(e){
      this.$emit('click', e)
    }
  }
}
</script>

<style scoped lang="less">
.sideToolButton {
  width: 3.8rem;
  height: 3.8rem;
  position: fixed;
  right: 1rem;
  background-color: rgba(0, 0, 0, .6);
  color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, .6);
  border-radius: @border-radius;
  z-index: 3;
  i{
    font-size: 2.4rem;
  }
}
</style>
