import vueCookies from 'vue-cookies'

const TokenKey = 'TOKEN'

export function getToken() {
  return vueCookies.get(TokenKey)
}

export function setToken(token) {
  return vueCookies.set(TokenKey, token, '7d')
}

export function removeToken() {
  return vueCookies.remove(TokenKey)
}
