<template>
  <div id="app" class="dfc">
    <!--头部导航-->
    <top-bar></top-bar>
    <!--主页面-->
    <div class="mine-page" @scroll.passive="mainPageScroll($event)">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view></router-view>
      </keep-alive>
      <router-view v-else></router-view>
    </div>
    <!--底部导航-->
    <bottom-nav></bottom-nav>
    <!--滚动距离大于500显示回到顶部按钮-->
    <side-tool-button :icon="'icon-top02'"
                      @click="goTop"
                      v-if="mainScrollTop > 500">
    </side-tool-button>
  </div>
</template>
<script>
import BottomNav from '@/components/bottom-nav'
import TopBar from '@/components/top-bar'
import {mapGetters} from 'vuex'
import {goTop, setFontSize, throttle} from '@/util/common'
import store from '@/store'

import {getUserInfo} from '@/api/user'
import {getToken} from '@/util/token'
export default {
  name: 'App',
  components: {TopBar, BottomNav},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      mainScrollTop: 'mainScrollTop'
    })
  },
  created() {
    this.getUserInfo()
  },
  mounted() {
    setFontSize()
    window.onresize = setFontSize
  },
  methods: {
    goTop,
    async getUserInfo() { // 获取用户信息
      if (!getToken()) return
      const res  = await getUserInfo()
      await this.$store.dispatch('setUserInfo', res.data)
    },
    mainPageScroll: throttle(e => { // 节流 监听页面滚动
      let el = e.target
      store.dispatch('setMainScrollTop', el.scrollTop)
      if (Math.abs(el.scrollTop + el.offsetHeight - el.scrollHeight) <= 50) { // 是否滚动到底部
        store.dispatch('setInScrollBottom', true)
      } else {
        store.dispatch('setInScrollBottom', false)
      }
    }, 200)
  }
}
</script>
<style scoped lang="less">
#app {
  position: relative;
  width: 100%;
  max-width: 60rem;
  height: 100%;
  margin: 0 auto;
}

.mine-page {
  height: 0;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
</style>
