import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import {Toast} from 'vant'
import router from '@/router'
import {getToken, removeToken} from '@/util/token'

// 响应时间
axios.defaults.timeout = 10 * 1000
// 配置cookie
// axios.defaults.withCredentials = true
// 配置请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 静态资源
Vue.prototype.$static = ''
// 跨域设置
axios.create({
  withCredentials: true
})

// 配置接口地址
axios.defaults.baseURL = '/api'
// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  config => {
    if (getToken()) { // 请求头插入token
      config.headers['token'] = getToken()
    }
    if (config.method === 'post') {
      if (config.headers['Content-Type'] !== 'multipart/form-data') {
        config.data = qs.stringify(config.data)
      } else {
        config.data = config.data
      }
    }
    return config
  },
  err => {
    Toast({
      message: err.msg || '请求失败'
    })
    return Promise.reject(err)
  }
)
// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  res => {
    if (res.data.code === 0) { // 请求正常
      return res
    } else if (res.data.code === -1) { // 未登录
      // 移除Token
      removeToken()
    } else { // 请求异常
      Toast({message: res.data.msg || '请求失败'})
      return res
    }
  },
  err => {
    Toast({message: '请求失败'})
    return Promise.reject(err)
  }
)

// 发送请求
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(
        res => {
          resolve(res.data)
        },
        err => {
          reject(err.data)
        }
      )
      .catch(err => {
        reject(err.data)
      })
  })
}

export function fetchGet(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
