<template>
  <button class="wi-button" :class="[type, {disabled}]" :disabled="disabled" @click="clickBtn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'WiButton',
  props: {
    type: {
      type: String,
      default: 'normal' // normal
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  components: {},
  created() {
  },
  methods: {
    clickBtn(e){
      this.$emit('click', e)
    }
  }
}
</script>

<style scoped lang="less">
.wi-button{
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;
  .button;
  &.disabled{
    background-color: @button-disabled-background-color;
    color: @button-disabled-color;
  }
}
</style>
