import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 添加原型方法
import '@/util/prototype.js'
// 自定义全局组件
import '@/components/common'
// css
import '@/style/base.css'
// 过滤
import '@/filters'
// app内返回问题
import '@/util/h5-plus'

Vue.config.productionTip = false

// cookie
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// vant
import 'vant/lib/index.less'

import {
  Toast,
  Skeleton,
  SwipeCell,
  Popup,
  Dialog,
  DatetimePicker,
  Tab,
  Tabs,
  Uploader,
  Swipe,
  SwipeItem,
  Lazyload,
  PullRefresh,
  List
} from 'vant'

Vue.use(Toast)
Vue.use(Skeleton)
Vue.use(SwipeCell)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(DatetimePicker)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Uploader)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Lazyload, {
  loading: '',
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
