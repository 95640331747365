<template>
  <div class="no-data dfc-XY-center">
    <img :src="noDataImg" alt="">
    <p>暂无数据</p>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  components: {},
  data() {
    return {
      noDataImg: require('@/assets/images/no-data.png')
    }
  },
  computed: {},
  created() {
  },
  methods: {}
}
</script>

<style scoped lang="less">
.no-data {
  padding-top: 10rem;

  img {
    width: 7rem;
  }

  p {
    font-size: 1.2rem;
    color: @no-data-color;
    padding: 1rem 0;
  }
}
</style>
