<template>
  <div class="setting-plug" @click="clickBtn" :class="{disabled}">
    <p class="label dfr-Y-center">
      <i class="iconfont mr-1" :class="icon"></i>
      {{ label }}
    </p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SettingPlug',
  props: {
    label: String,
    icon: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  methods: {
    clickBtn(e) {
      if (this.disabled){
        return
      }
      this.$emit('click', e)
    }
  }
}
</script>

<style scoped lang="less">
.setting-plug {
  .label{
    font-size: 1.4rem;
    line-height: 4.4rem;
  }
  .iconfont{
    font-size: 2rem;
  }
}
</style>
