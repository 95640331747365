import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './types'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  state: { //存放的键值对就是所要管理的状态
    userInfo: null,
    mainScrollTop: 0,
    inScrollBottom: false
  },
  mutations: { // 储存数据
    [types.SET_USER_INFO](state, data) {
      state.userInfo = data
    },
    [types.SET_MAIN_SCROLL_TOP](state, data) {
      state.mainScrollTop = data
    },
    [types.SET_IN_SCROLL_BOTTOM](state, data) {
      state.inScrollBottom = data
    }
  },
  actions: { // 中间件 处理数据
    [types.SET_USER_INFO](context, data) {
      context.commit(types.SET_USER_INFO, data)
    },
    [types.SET_MAIN_SCROLL_TOP](context, data) {
      context.commit(types.SET_MAIN_SCROLL_TOP, data)
    },
    [types.SET_IN_SCROLL_BOTTOM](context, data) {
      context.commit(types.SET_IN_SCROLL_BOTTOM, data)
    }
  },
  getters: {
    userInfo: state => state.userInfo,
    mainScrollTop: state => state.mainScrollTop,
    inScrollBottom: state => state.inScrollBottom
  }
})

export default store
