// 格式化时间
export function dateFormat(date, fmt = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    date = new Date(date.replace(/-/g, '/'))
  }
  if (typeof date === 'number') {
    date = new Date(date)
  }
  let o = {
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S': date.getMilliseconds()
  }
  let week = {
    '0': '\u65e5',
    '1': '\u4e00',
    '2': '\u4e8c',
    '3': '\u4e09',
    '4': '\u56db',
    '5': '\u4e94',
    '6': '\u516d'
  }
  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[date.getDay() + ''])
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

//生成从minNum到maxNum的随机数
export function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10)
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
    default:
      return 0
  }
}

// 获取时间戳
export function timestampToTime(timestamp) { // 时间戳转换为时间
  let date = new Date(timestamp * 1000)//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-'
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  let D = date.getDate() + ' '
  let h = date.getHours() + ':'
  let m = date.getMinutes() + ':'
  let s = date.getSeconds()
  return Y + M + D + h + m + s
}

// 回到顶部
export function goTop() {
  let selectors = '.mine-page'
  let scrollTop = document.querySelector(selectors).scrollTop
  // 清空上一次的定时器
  let timer = null
  clearInterval(timer)
  timer = setInterval(() => {
    scrollTop -= scrollTop / 10
    document.querySelector(selectors).scrollTo(0, scrollTop)
    if (scrollTop < 2) {
      document.querySelector(selectors).scrollTo(0, 0)
      clearInterval(timer)
    }
  }, 10)
}

// rem
export function setFontSize() {
  let fontSize = (document.querySelector('html').offsetWidth / 375 * 10 > 14 ? 14 : document.querySelector('html').offsetWidth / 375 * 10) + 'px'
  document.querySelector('html').style.fontSize = fontSize
}


/**
 * @param {Function} fn 目标函数
 * @param {Number} time 延迟执行毫秒数
 * @param {Boolean} immediate true - 立即执行 false - 延迟执行
 * @description 防抖函数
 */
export function debounce(fn, time, immediate = true) {
  let timer
  return function () {
    const that = this
    const args = arguments

    if (timer) clearTimeout(timer)
    if (immediate) {
      const callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, time)
      if (callNow) {
        fn.apply(that, args)
      }
    } else {
      timer = setTimeout(() => {
        fn.apply(that, args)
      }, time)
    }
  }
}

/**
 * @param {Function} fn 目标函数
 * @param {Number} time 延迟执行毫秒数
 * @param {Number} type 1-立即执行，2-不立即执行
 * @description 节流函数
 */
export function throttle(fn, time, type = 2) { // 节流阀
  let previous = 0
  let timeout
  return  function () {
    let that = this
    let args = arguments
    if (type === 1) {
      let now = Date.now()
      if (now - previous > time) {
        fn.apply(that, args)
        previous = now
      }
    } else if (type === 2) {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null
          fn.apply(that, args)
        }, time)
      }
    }
  }
}

export function deepCopy(data){
  return JSON.parse(JSON.stringify(data))
}

export function pageScrollTop(value){
  document.querySelector('.mine-page').scrollTop = value
}
