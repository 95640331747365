let catalogue = [
  {
    key: 'excerpt',
    label: 'Excerpt',
    path: '/excerpt-group',
    show: true
  },
  {
    key: 'home',
    label: 'Home',
    path: '/home',
    show: true
  },
  {
    key: 'days',
    label: 'Days',
    path: '/days',
    show: true
  }
]
export default catalogue
